import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { User, Trash } from "phosphor-react";
import useStyles from "../../NewOrganizationsOverview/NewOrganizationsOverview.styles";
import { fget } from "../../../../../API/callsApi";
import CommonLoader from "../../../../ReusableComponents/CommonLoader/CommonLoader";
import NoDataFound from "../../../../ReusableComponents/NoDataFound/NoDataFound";

const InvitationsTable = ({ currentOrganization, userSearchValue }) => {
  const classes = useStyles();
  const [allInvitedUsers, setAllInvitedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getInvitedUser = async () => {
    setIsLoading(true);
    const url = `/organization/${currentOrganization}/sent-invites/`;
    try {
      const res = await fget({ url });
      if (res?.status === 200) {
        const userList = res?.data.filter(
          (user) => user?.status !== "Rejected"
        );
        setAllInvitedUsers(userList);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getInvitedUser();
  }, [currentOrganization]);

  return (
    <>
      <TableContainer
        style={{
          height: "6%",
          overflow: "hidden",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={`${classes.tableRowHeadService} ${classes.tableHead}`}
              >
                User
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableHead}`}
              >
                User Role
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableHead}`}
              >
                Email ID
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableHead}`}
              >
                Details
              </TableCell>
              <TableCell
                align="right"
                className={`${classes.tableRowHeadService} ${classes.tableHead}`}
              >
                <span style={{ marginRight: "40px" }}> Action</span>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <Box
        style={{
          height: "87%",
          overflowY: "auto",
          border: "1px solid black",
          borderRadius: "5px",
        }}
      >
        {isLoading ? (
          <div className={classes.loaderContainer}>
            <CommonLoader />
          </div>
        ) : allInvitedUsers && allInvitedUsers.length > 0 ? (
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody style={{ height: "100%" }}>
                {allInvitedUsers
                  ?.filter((search) => {
                    if (userSearchValue === undefined) {
                      return search;
                    } else if (userSearchValue === "") {
                      return search;
                    } else if (
                      search?.user?.first_name
                        ?.toLowerCase()
                        .includes(userSearchValue.toLowerCase())
                    ) {
                      return search;
                    } else if (
                      search?.user?.last_name
                        ?.toLowerCase()
                        .includes(userSearchValue.toLowerCase())
                    ) {
                      return search;
                    }
                    return null;
                  })
                  .map((eachUser) => (
                    <TableRow
                      key={eachUser.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={`${classes.tableRowHeadService} ${classes.tableRowCells}`}
                      >
                        <Typography variant="h5" fontWeight={500}>
                          {eachUser.user?.first_name
                            ? `${eachUser.user?.first_name} ${eachUser.user?.last_name}`
                            : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
                      >
                        <Typography
                          variant="h5"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "5px",
                          }}
                        >
                          <User />{" "}
                          {eachUser.user?.role ? eachUser.user?.role : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
                      >
                        <Typography variant="h5">
                          {eachUser.user_email ? eachUser.user_email : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
                      >
                        <Typography variant="h5">
                          by {eachUser.sent_by.first_name}{" "}
                          {eachUser.sent_by.last_name}
                          <br />
                          on{" "}
                          {new Date(eachUser.sent_date).toLocaleDateString(
                            "en-US",
                            {
                              month: "numeric",
                              day: "numeric",
                              year: "numeric",
                            }
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        className={`${classes.tableRowHeadService} ${classes.tableRowCells}`}
                      >
                        <Button
                          style={{
                            margin: "0px",
                            padding: "5px",
                            minWidth: "0px",
                            textTransform: "none",
                            marginRight: "40px",
                          }}
                        >
                          <Trash size={20} color="#E01717" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <NoDataFound />
        )}
      </Box>
    </>
  );
};

export default InvitationsTable;
