import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@mui/material";
import useStyles from "../../NewOrganizationsOverview/NewOrganizationsOverview.styles";
import { fget } from "../../../../../API/callsApi";
import CommonLoader from "../../../../ReusableComponents/CommonLoader/CommonLoader";
import NoDataFound from "../../../../ReusableComponents/NoDataFound/NoDataFound";
import ActiveUserTableItems from "./ActiveUserTableItems";

const ActiveUsersTable = ({
  currentOrganization,
  selectedOrg,
  userSearchValue,
  userRoleValue,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [allActiveUsers, setAllActiveUsers] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [refreshCounter, setRefreshCounter] = useState(false);

  const getActiveUser = async () => {
    setIsLoading(true);
    const url = `/organization/${currentOrganization}/users/`;
    try {
      const res = await fget({ url });
      if (res?.status === 200) {
        setAllActiveUsers(res?.data);
        setActiveUsers(res.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      userRoleValue === "User" ||
      userRoleValue === "Admin" ||
      userRoleValue === "Owner"
    ) {
      const currentUsers = activeUsers.filter(
        (user) => user.role === userRoleValue
      );
      setAllActiveUsers(currentUsers);
    } else if (userRoleValue === "All") {
      getActiveUser();
    }
  }, [userRoleValue]);

  const handleRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  useEffect(() => {
    getActiveUser();
  }, [currentOrganization, refreshCounter]);

  return (
    <>
      <TableContainer style={{ height: "6%", overflow: "hidden" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={`${classes.tableRowHeadService} ${classes.tableHead}`}
              >
                User
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableHead}`}
              >
                User Role
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableHead}`}
              >
                Email ID
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableHead}`}
              >
                Joining Date
              </TableCell>
              <TableCell
                align="right"
                className={`${classes.tableRowHeadService} ${classes.tableHead}`}
              >
                <span style={{ marginRight: "40px" }}>Action</span>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <Box
        style={{
          height: "87%",
          overflowY: "auto",
          border: "1px solid black",
          borderRadius: "5px",
        }}
      >
        {isLoading ? (
          <div className={classes.loaderContainer}>
            <CommonLoader />
          </div>
        ) : allActiveUsers && allActiveUsers.length > 0 ? (
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody style={{ height: "100%" }}>
                {allActiveUsers
                  ?.filter((search) => {
                    if (userSearchValue === undefined) {
                      return search;
                    } else if (userSearchValue === "") {
                      return search;
                    } else if (
                      search?.first_name
                        ?.toLowerCase()
                        .includes(userSearchValue.toLowerCase())
                    ) {
                      return search;
                    } else if (
                      search?.last_name
                        ?.toLowerCase()
                        .includes(userSearchValue.toLowerCase())
                    ) {
                      return search;
                    }
                    return null;
                  })
                  .map((eachUser) => (
                    <ActiveUserTableItems
                      key={eachUser.id}
                      eachUser={eachUser}
                      selectedOrg={selectedOrg}
                      handleRefreshCounter={handleRefreshCounter}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <NoDataFound />
        )}
      </Box>
    </>
  );
};

export default ActiveUsersTable;
