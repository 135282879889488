import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography, Grid } from "@mui/material";
import { Plus, Sparkle } from "phosphor-react";
import { FilledButton } from "../../../ReusableComponents/Buttons/Buttons";
import { fget } from "../../../../API/callsApi";
import CommonLoader from "../../../ReusableComponents/CommonLoader/CommonLoader";
import NoDataFound from "../../../ReusableComponents/NoDataFound/NoDataFound";
import MovieColabIcon from "../../../ReusableComponents/Svgs/MovieColabIcon";

const ManageServices = ({ currentOrganization }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [orgServices, serOrgServices] = useState([]);

  const getOrgServices = async () => {
    setIsLoading(true);
    const url = `/admin/service/?org=${currentOrganization}`;
    try {
      const res = await fget({ url });
      if (res?.status === 200) {
        const filteredData = res.data.filter(
          (eachService) => eachService.name === "MovieColab"
        );
        serOrgServices(filteredData);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getOrgServices();
  }, [currentOrganization]);

  return (
    <>
      <Box
        mt={7}
        style={{
          height: "93%",
          overflowY: "auto",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        {isLoading ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CommonLoader />
          </div>
        ) : orgServices && orgServices.length > 0 ? (
          orgServices.map((eachService) => {
            return eachService.license_stats.total > 0 ? (
              <Box
                key={eachService.id}
                style={{
                  boxSizing: "border-box",
                  width: "100%",
                  padding: "20px",
                  borderRadius: "10px",
                  border: "1px solid #666666",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <MovieColabIcon />
                  <Typography variant="h2" fontWeight={600}>
                    {eachService.name}
                  </Typography>
                </div>
                <Grid container mt={3}>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={5}>
                        <Typography variant="h5" color="#666666">
                          Total Licenses
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        :
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="h5">
                          {eachService.license_stats.total}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container mt={3}>
                      <Grid item xs={5}>
                        <Typography variant="h5" color="#666666">
                          Licenses in use
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        :
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="h5">
                          {eachService.license_stats.used}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={5}>
                        <Typography variant="h5" color="#666666">
                          Expired Licenses
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        :
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="h5">
                          {eachService.license_stats.expired}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container mt={3}>
                      <Grid item xs={5}>
                        <Typography variant="h5" color="#666666">
                          Available Licenses
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        :
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="h5">
                          {eachService.license_stats.available}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="flex-end"
                  >
                    <div style={{ width: "220px" }}>
                      <FilledButton
                        small={true}
                        text="Manage Licenses"
                        submit={() =>
                          history.push(
                            `/organizations/${currentOrganization}/services/${eachService.id}/license/?licensetype=active&serviceName=${eachService.name}`
                          )
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                key={eachService.id}
                style={{
                  boxSizing: "border-box",
                  width: "100%",
                  padding: "20px",
                  borderRadius: "10px",
                  border: "1px solid #666666",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Typography
                      variant="h2"
                      fontWeight={600}
                      style={{ marginRight: "10px" }}
                    >
                      {eachService.name}
                    </Typography>
                    <Sparkle size={20} color="#178CE0" />
                    <Typography variant="h6" color="#178CE0">
                      New!
                    </Typography>
                  </div>
                  <div style={{ width: "220px" }}>
                    <FilledButton
                      small={true}
                      icon={<Plus />}
                      text="Add License"
                      submit={() =>
                        history.push(
                          `/organizations/${currentOrganization}/services/${eachService.id}/payment`
                        )
                      }
                    />
                  </div>
                </div>
              </Box>
            );
          })
        ) : (
          <NoDataFound />
        )}
      </Box>
    </>
  );
};

export default ManageServices;
