import React, { useState, useEffect } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  InputAdornment,
  Typography,
  Button,
} from "@mui/material";
import { Check, MagnifyingGlass, X } from "phosphor-react";
import useStyles from "../DashBoardOverview/DashBoard.styles";
import { fget, fpatch } from "../../../API/callsApi";
import toast from "react-hot-toast";
import { FilledButton } from "../../ReusableComponents/Buttons/Buttons";
import CommonLoader from "../../ReusableComponents/CommonLoader/CommonLoader";
import NoDataFound from "../../ReusableComponents/NoDataFound/NoDataFound";
import useAuth from "../../../hooks/useAuth";

const MyInvitationsTable = () => {
  const classes = useStyles();
  const { getOrgList } = useAuth();
  const [invitations, setInvitations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    getInvitations();
  }, [refreshCounter]);

  const getInvitations = async () => {
    setIsLoading(true);
    const url = `/organization/recieved-invites/`;
    try {
      const res = await fget({ url });
      if (res?.status === 200) {
        setInvitations(res?.data, invitations);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const acceptInvitation = async (id) => {
    const acceptData = {
      url: `/organization/accept-invite/${id}/`,
      data: {
        accepted: true,
      },
    };
    try {
      const res = await fpatch(acceptData);
      if (res?.status === 200) {
        setRefreshCounter(refreshCounter + 1);
        toast.success("Successfully Accepted Invitation");
        getOrgList();
      }
    } catch (error) {
      toast.error("Error on Accepting Invitation");
    }
  };

  const rejectInvitation = async (id) => {
    const acceptData = {
      url: `/organization/accept-invite/${id}/`,
      data: {
        accepted: false,
      },
    };
    try {
      const res = await fpatch(acceptData);
      if (res?.status === 200) {
        setRefreshCounter(refreshCounter + 1);
        toast.success("Invitation Rejected");
      }
    } catch (error) {
      toast.error("Error on rejecting Invitation");
    }
  };

  return (
    <>
      <div
        style={{
          height: "8%",
        }}
      >
        <TextField
          label="search"
          variant="outlined"
          size="small"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MagnifyingGlass size={22} />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <TableContainer style={{ height: "7%", overflow: "hidden" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={`${classes.tableRowHeadInvitationTwo} ${classes.tableRowCellsColor} ${classes.tableHead}`}
              >
                Organization
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadInvitation} ${classes.tableRowCellsColor} ${classes.tableHead}`}
              >
                Added By
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadInvitation} ${classes.tableRowCellsColor} ${classes.tableHead}`}
              >
                Added on
              </TableCell>
              <TableCell
                align="right"
                className={`${classes.tableRowHeadInvitation} ${classes.tableRowCellsColor} ${classes.tableHead}`}
                style={{ paddingRight: "45px" }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <Box
        style={{
          height: "84%",
          overflowY: "auto",
          border: "1px solid black",
          borderRadius: "5px",
        }}
      >
        {isLoading ? (
          <div className={classes.loaderContainer}>
            <CommonLoader />
          </div>
        ) : invitations && invitations.length > 0 ? (
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody style={{ height: "100%" }}>
                {invitations
                  ?.filter((search) => {
                    if (searchValue === undefined) {
                      return search;
                    } else if (searchValue === "") {
                      return search;
                    } else if (
                      search.org?.name
                        ?.toLowerCase()
                        .includes(searchValue.toLowerCase())
                    ) {
                      return search;
                    }
                    return null;
                  })
                  .map((eachInvitation) => (
                    <TableRow
                      key={eachInvitation.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={`${classes.tableRowHeadInvitationTwo}`}
                      >
                        <Typography variant="h5" style={{ fontWeight: "600" }}>
                          {eachInvitation.org?.name}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`${classes.tableRowHeadInvitation} ${classes.tableRowCellsColor}`}
                      >
                        <Typography variant="h5">
                          {eachInvitation.sent_by?.first_name}{" "}
                          {eachInvitation.sent_by?.last_name}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`${classes.tableRowHeadInvitation} ${classes.tableRowCellsColor}`}
                      >
                        <Typography variant="h5">
                          {new Date(
                            eachInvitation.sent_date
                          ).toLocaleDateString("en-US", {
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          })}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        className={`${classes.tableRowHeadInvitation}`}
                      >
                        {eachInvitation.status === "Pending" ? (
                          <Box
                            sx={{
                              display: "flex",
                              gap: "10px",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Box sx={{ width: "100px" }}>
                              <FilledButton
                                icon={<Check />}
                                disabled={false}
                                text="Accept"
                                submit={() =>
                                  acceptInvitation(eachInvitation?.id)
                                }
                              />
                            </Box>
                            <Button
                              startIcon={<X />}
                              onClick={() =>
                                rejectInvitation(eachInvitation?.id)
                              }
                              sx={{
                                minWidth: "0px",
                                width: "100px",
                                backgroundColor: "#FFFFFF",
                                color: "#E01717 !important",
                                border: "2px solid #E01717",
                                borderRadius: "5px",
                                padding: "10px",
                                textTransform: "none",
                                "&:hover": {
                                  backgroundColor: "#D9D9D9",
                                },
                              }}
                            >
                              Reject
                            </Button>
                          </Box>
                        ) : (
                          <Typography
                            variant="h5"
                            style={{
                              fontWeight: "600",
                              paddingRight: "15px",
                              color:
                                eachInvitation.status === "Rejected"
                                  ? "#E01717"
                                  : "#000000",
                            }}
                          >
                            Invitation {eachInvitation.status}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <NoDataFound />
        )}
      </Box>
    </>
  );
};

export default MyInvitationsTable;
