import React, { useEffect, useState } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import useStyles from "../DashBoardOverview/DashBoard.styles";
import { ArrowSquareOut } from "phosphor-react";
import { fget } from "../../../API/callsApi";
import toast from "react-hot-toast";
import CommonLoader from "../../ReusableComponents/CommonLoader/CommonLoader";
import NoDataFound from "../../ReusableComponents/NoDataFound/NoDataFound";

const MyServicesTable = ({ organizationsList }) => {
  const classes = useStyles();
  const [currentOrg, setCurrentOrg] = useState("all");
  const [orgStatus, setOrgStatus] = useState("all");
  const [servicesList, setServicesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getServices = async () => {
    setIsLoading(true);
    let url = "/user/service/";
    if (currentOrg !== "all" && orgStatus !== "all") {
      url += `?org=${currentOrg}&status=${orgStatus}`;
    } else if (currentOrg !== "all" && orgStatus === "all") {
      url += `?org=${currentOrg}`;
    } else if (currentOrg === "all" && orgStatus !== "all") {
      url += `?status=${orgStatus}`;
    }
    try {
      const res = await fget({
        url,
      });
      if (res?.status === 200 || res.status === 201) {
        setServicesList(res.data);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("couldn't load services");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getServices();
  }, [currentOrg, orgStatus]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
          marginBottom: "5px",
          height: "7%",
        }}
      >
        <FormControl size="small" style={{ width: "200px" }}>
          <InputLabel id="demo-simple-select-helper-label-org">
            Organization
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label-org"
            variant="outlined"
            label="Organization"
            value={currentOrg}
            onChange={(event) => setCurrentOrg(event.target.value)}
          >
            <MenuItem value="all">All</MenuItem>
            {organizationsList.map((eachOrg) => {
              return (
                <MenuItem value={eachOrg.id} key={eachOrg.id}>
                  {eachOrg.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl size="small">
          <InputLabel
            style={{ padding: "0px 5px" }}
            id="demo-simple-select-helper-label-status"
          >
            Status
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label-status"
            id="demo-simple-select-helper-label-status"
            variant="outlined"
            label="Status"
            style={{ width: "200px", height: "100%" }}
            value={orgStatus}
            onChange={(event) => setOrgStatus(event.target.value)}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">InActive</MenuItem>
          </Select>
        </FormControl>
      </div>
      <TableContainer style={{ height: "7%", overflow: "hidden" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor} ${classes.tableHead}`}
              >
                Service Name
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor} ${classes.tableHead}`}
              >
                Organization
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor} ${classes.tableHead}`}
              >
                Added on
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor} ${classes.tableHead}`}
              >
                Status
              </TableCell>
              <TableCell
                align="right"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor} ${classes.tableHead}`}
              >
                <span style={{ paddingRight: "45px" }}>Action</span>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <Box
        style={{
          height: "84.5%",
          overflowY: "auto",
          border: "1px solid black",
          borderRadius: "5px",
        }}
      >
        {isLoading ? (
          <div className={classes.loaderContainer}>
            <CommonLoader />
          </div>
        ) : servicesList && servicesList.length > 0 ? (
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody style={{ height: "100%" }}>
                {servicesList.map((eachService) => (
                  <TableRow
                    key={eachService.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={`${classes.tableRowHeadService} ${classes.tableRowCells}`}
                    >
                      <Typography variant="h5" style={{ fontWeight: "600" }}>
                        {eachService.name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`${classes.tableRowHeadService} ${classes.tableRowCells}`}
                    >
                      <Typography variant="h5" style={{ fontWeight: "600" }}>
                        {eachService.organization.name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
                    >
                      <Typography variant="h5">
                        {new Date(eachService.added_on).toLocaleDateString(
                          "en-US",
                          {
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          }
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
                    >
                      <Typography variant="h5">
                        {eachService.status.slice(0, 1).toUpperCase()}
                        {eachService.status.slice(1)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      className={`${classes.tableRowHeadService} ${classes.tableRowCells}`}
                    >
                      <Button
                        disabled={
                          eachService.service_urls.frontend_url === null
                        }
                        style={{
                          margin: "0px 15px 0px 0px",
                          padding: "3px 10px",
                          border: "2px solid #000000",
                          borderRadius: "5px",
                          textTransform: "none",
                          color: "#000000",
                        }}
                        onClick={() =>
                          eachService.service_urls.frontend_url &&
                          window.open(eachService.service_urls.frontend_url)
                        }
                        startIcon={<ArrowSquareOut size={18} />}
                      >
                        Go to App
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <NoDataFound />
        )}
      </Box>
    </>
  );
};

export default MyServicesTable;
