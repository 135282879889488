import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@mui/material";
import { fget } from "../../../API/callsApi";
import useStyles from "../LicensesOverview/LicensesOverview.styles";
import CommonLoader from "../../ReusableComponents/CommonLoader/CommonLoader";
import NoDataFound from "../../ReusableComponents/NoDataFound/NoDataFound";
import CancelledLicenseTableItem from "./CancelledLicenseTableItem";

const CancelledLicensesTable = ({ licenseSearchValue }) => {
  const classes = useStyles();
  const params = useParams();
  const [cancelledLicense, setCancelledLicense] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getLicenses = async () => {
    if (params.sid) {
      setIsLoading(true);
      try {
        const res = await fget({
          url: `/admin/license/?org=${params.orgId}&service=${params.sid}&status=cancelled`,
        });
        setIsLoading(false);
        setCancelledLicense(res.data);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getLicenses();
  }, [params.sid]);

  return (
    <>
      <TableContainer style={{ height: "8%", overflow: "hidden" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                License ID
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                User
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                Subscribed on
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                Expired on
              </TableCell>
              <TableCell
                align="right"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                <span style={{ marginRight: "40px" }}>Action</span>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <Box
        style={{
          height: "83%",
          overflowY: "auto",
          border: "1px solid black",
          borderRadius: "5px",
        }}
      >
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody style={{ height: "100%" }}>
              {isLoading ? (
                <div className={classes.commonLoader}>
                  <CommonLoader />
                </div>
              ) : cancelledLicense && cancelledLicense.length > 0 ? (
                cancelledLicense
                  ?.filter((search) => {
                    if (licenseSearchValue === undefined) {
                      return search;
                    } else if (licenseSearchValue === "") {
                      return search;
                    } else if (
                      search.license_number
                        .toLowerCase()
                        .includes(licenseSearchValue.toLowerCase())
                    ) {
                      return search;
                    } else if (
                      search.user &&
                      search.user.first_name
                        .toLowerCase()
                        .includes(licenseSearchValue.toLowerCase())
                    ) {
                      return search;
                    } else if (
                      search.user &&
                      search.user.last_name
                        .toLowerCase()
                        .includes(licenseSearchValue.toLowerCase())
                    ) {
                      return search;
                    }
                    return null;
                  })
                  .map((eachLicense) => {
                    return (
                      <CancelledLicenseTableItem
                        key={eachLicense.id}
                        eachLicense={eachLicense}
                      />
                    );
                  })
              ) : (
                <NoDataFound />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default CancelledLicensesTable;
