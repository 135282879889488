import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@mui/material";
import useStyles from "../ManageTransactionAndInvoicesOverview/ManageTransactionAndInvoicesOverview.styles";
import { fget } from "../../../../../API/callsApi";
import CommonLoader from "../../../../ReusableComponents/CommonLoader/CommonLoader";
import NoDataFound from "../../../../ReusableComponents/NoDataFound/NoDataFound";
import TransactionSuccessfulTableItem from "./TransactionSuccessfulTableItem";
import useAuth from "../../../../../hooks/useAuth";

const TransactionSuccessfulTable = () => {
  const classes = useStyles();
  const { currentOrg } = useAuth();
  const [successfulTransactions, setSuccessfulTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getSuccessfullTransaction = async () => {
    setIsLoading(true);
    try {
      const res = await fget({
        url: `/transactions/?payment_status=successful&org=${currentOrg.id}`,
      });
      if (res.status === 200 || res.status === 201) {
        setSuccessfulTransactions(res.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSuccessfullTransaction();
  }, [currentOrg.id]);

  return (
    <>
      <TableContainer style={{ height: "8%", overflow: "hidden" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                <span style={{ marginLeft: "10px" }}>Transaction ID</span>
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                Transaction Date
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                License/ Invoice
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                Type
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                Amount
              </TableCell>
              <TableCell align="right" className={classes.tableRowHeadService}>
                <span style={{ marginRight: "40px" }}>Action</span>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <Box
        style={{
          height: "87%",
          overflowY: "auto",
          border: "1px solid black",
          borderRadius: "5px",
        }}
      >
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody style={{ height: "100%" }}>
              {isLoading ? (
                <div className={classes.commonLoader}>
                  <CommonLoader />
                </div>
              ) : successfulTransactions &&
                successfulTransactions.length > 0 ? (
                successfulTransactions.map((eachTransaction) => {
                  return (
                    <TransactionSuccessfulTableItem
                      key={eachTransaction.id}
                      eachTransaction={eachTransaction}
                    />
                  );
                })
              ) : (
                <NoDataFound />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default TransactionSuccessfulTable;
